<template>
  <div>
    <common-header></common-header>
    <div class="warp">
      <!-- 筛选条件 start -->
      <div class="condition">
        <div class="selection-box">
          <div class="item" @click="openPicker()">
            <input type="text" readonly placeholder="订单日期起" :value="startTime">
            <i class="icon-black-arrow"></i>
          </div>
          <div class="item" @click="openPicker2()">
            <input type="text" readonly placeholder="订单日期止" :value="endTime">
            <i class="icon-black-arrow"></i>
          </div>
        </div>
        <div class="selection-box">
          <div class="item"  @click="commodityType = true">
            <input type="text" readonly placeholder="商品分类" :value="sureXz.name">
            <i class="icon-black-arrow"></i>
          </div>
          <div class="item search-box" @click="search">
            查询
          </div>
        </div>
      </div>
      <!-- 筛选条件 end -->

      <div class="shop-info" v-if="da != ''">
        <div class="name"><span class="ddd">{{da.curUser}}</span> <div class="clear" @click="searchAll" v-if="clearBtnShow"></div></div>
        <div class="totalAmount">总金额：<b>{{da.totalAmount}}</b></div>
      </div>

      <!-- 商品列表 start -->
      <div class="commodity-list">
        <table>
          <tr>
            <th>商品</th>
            <th>规格</th>
            <th>单位</th>
            <th>数量</th>
            <th>金额</th>
          </tr>
          <tr v-for="(item, index) in da.commodity" :key="index">
            <td class="name">{{item.commodityName}}</td>
            <td class="specName">{{item.specName}}</td>
            <td>{{item.minUnitName}}</td>
            <td>{{item.minQuantity}}</td>
            <td>{{item.totalPrice}}</td>
          </tr>
        </table>
        <div v-if="da.commodity && da.commodity.length == 0" style="text-align: center; margin-top:.2rem;font-size:.28rem">空空如也~</div>
      </div>
      <!-- 商品列表 end -->

    </div>
    <mt-datetime-picker
      ref="picker"
      type="date"
      year-format="{value} 年"
      month-format="{value} 月"
      :endDate="endDate"
      @confirm="handleConfirm"
      v-model="xzStartTime"
    >
    </mt-datetime-picker>

    <mt-datetime-picker
      ref="picker2"
      type="date"
      year-format="{value} 年"
      month-format="{value} 月"
      class="time2"
      :endDate="endDate"
      @confirm="handleConfirm2"
      v-model="xzEndTime"
    >
    </mt-datetime-picker>

    
    <mt-popup class="w100" v-model="commodityType" position="bottom">
      <div class="select-popup-btn fix">
        <span class="btn fl" @click="commodityType = false">取消</span>
        <span class="btn fr" @click="modifyCommodity">确定</span>
      </div>
      <mt-picker
        :slots="commoditySlots"
        @change="commodityChange"
        value-key="name"
      ></mt-picker>
    </mt-popup>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { DateFormat, onBridgeReadyStart, isWeiXin } from "@/utils/common";
export default {
  data() {
    return {
      commodityType: false,
      startTime: "",
      endTime: "",
      endDate: new Date(),
      timeStartValue: new Date(),
      xzStartTime: new Date(),
      xzEndTime: new Date(),
      commodityObj: "",
      sureXz: "",
      commoditySlots: [
        {
          flex: 1,
          defaultIndex:4,
          values: [],
          className: "commodity-slots-class",
          textAlign: "center",
        },
      ],
      da: '',
      userId: "",
      clearBtnShow:true,
    }
  },
  mounted() {
    this.userId = this.$route.query.userId;
    this.clearBtnShow = this.userId?true:false;
    this.CommodityClass({
      level:1,
    }).then((res)=> {
      let ar = [{
        name:"所有分类",
        id: "",
      }]
      this.commoditySlots[0].values = ar.concat(res.data);
    })
    this.search({})
  },
  methods: {
    showLoadTxt(type) {
      if (type == 'show') {
        this.$Indicator.open({
          text: "加载中...",
          spinnerType: "snake"
        });
      } else {
        this.$Indicator.close();
      }
    },
    searchAll() {
      this.userId = "";
      this.clearBtnShow = false;
      this.search({})
    },
    commodityChange(picker, values) {
      this.commodityObj = values[0] ? values[0] : "";
    },
    modifyCommodity() {
      this.sureXz = this.commodityObj;
      this.commodityType = false;
    },
    search(obj) {
      this.showLoadTxt('show');
      this.getTgOrderDes({
        userId: this.userId,
        categoryFirst: this.sureXz.id,
        endDate: this.endTime || '',
        startDate: this.startTime || '',
      }).then((res)=> {
        this.da = res.data;
        this.startTime = res.data.startDate;
        this.endTime = res.data.endDate;
        this.showLoadTxt('hide');
      })
    },
    ...mapActions('account', [
      'getTgOrderDes',
    ]),
    ...mapActions('shopping', [
      'CommodityClass',
    ]),
    openPicker(i) {
      this.$refs.picker.open();
    },
    openPicker2(i) {
      this.$refs.picker2.open();
    },
    handleConfirm(e) {
      this.startTime = DateFormat(e).substring(0, 10);
    },
    handleConfirm2(e) {
      this.endTime = DateFormat(e).substring(0, 10);
    },
  },
}
</script>
<style lang='less' scoped>
.commodity-list{
  background: #fff;
  border-radius: .15rem;
  margin-top: .2rem;
  padding: .2rem 0;
  .name{
    padding: 0 .1rem;
    width: 2rem;
    max-width: 2rem;
    white-space:nowrap;
    overflow-x: scroll;
  }
  .specName{
    padding: 0 .1rem;
    width: 1.5rem;
    max-width: 1.5rem;
    white-space:nowrap;
    overflow-x: scroll;
  }
  table{
    width: 100%;
    text-align: center;
    th,td{
      font-size: .3rem;
      padding: .15rem 0;
    }
  }
}
.shop-info{
  background: #fff;
  margin-top: .2rem;
  border-radius: 0.15rem;
  font-size: .3rem;
  display: flex;
  padding: .2rem;
  justify-content: space-between;
  .clear{
    width: .4rem;
    height: .4rem;
    border-radius: 50%;
    color: #333;
    margin-left: .2rem;
    margin-right: .4rem;
    background-image: url('../../assets/images/img/clear-input.png');
    background-size: 100% 100%;
  }
  .name{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ddd{
    font-weight: bold;
    max-width: 3rem;
    overflow: hidden;/*超出部分隐藏*/
    white-space: nowrap;/*不换行*/
    text-overflow:ellipsis;/*超出部分文字以...显示*/
  }
}
.selection-box .item.search-box{
  color: #fff;
  justify-content: center !important;
  background: rgba(255,113,65,1);/* Old Browsers */
	background: -moz-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* FF3.6+ */
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,113,65,1)), color-stop(100%, rgba(255,185,139,1)));/* Chrome, Safari4+ */
	background: -webkit-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* IE 10+ */
	background: linear-gradient(to right, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%);/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7141', endColorstr='#ffb98b', GradientType=1 );/* IE6-9 */
}
.selection-box {
  display: flex;
  margin-top: .2rem;
  input{
    font-size: .3rem;
  }
  .item {
    height: .8rem;
    background: #fff;
    border: 1px solid #f5f5f5;
    padding: 0.2rem;
    flex: 1;
    margin-right: 0.2rem;
    border-radius: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.28rem;
    input{
      width: 100%;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>